



























import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class ViewHelp extends Vue {
  text = ''

  sendHelp() {
    this.$store
      .dispatch('sendHelp', {
        text: this.text,
        userId: this.$store.getters['loggedUser'].id,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          this.$router.back()
        }
      })
  }
}
